import ApiHelper from "../../utils/apiHelper";
// actions
import { globalActionCreators } from "./globalModule";
import { isAllowedBookingStatus } from "../../utils/helpers/isAllowedBookingStatus";

export const confirmationActionTypes = {
  SET_BOOKING_STATUS: "SET_BOOKING_STATUS",
  SET_API_ERROR: "SET_API_ERROR",
  TOGGLE_LOADING_BOOKING_STATUS: "TOGGLE_LOADING_BOOKING_STATUS",
  TOGGLE_LOADING_DOWNLOAD_PDF: "TOGGLE_LOADING_DOWNLOAD_PDF"
};

export const initialState = {
  bookingStatus: null,
  apiError: false,
  isBookingStatusLoading: true,
  isLoadingDownloadPdf: false
};

const bookingConfirmationModule = (state = initialState, action: any) => {
  switch (action.type) {
    case confirmationActionTypes.SET_BOOKING_STATUS:
      return {
        ...state,
        bookingStatus: action.bookingStatus
      };
    case confirmationActionTypes.SET_API_ERROR:
      return {
        ...state,
        apiError: action.apiError
      };
    case confirmationActionTypes.TOGGLE_LOADING_BOOKING_STATUS:
      return {
        ...state,
        isBookingStatusLoading: action.isBookingStatusLoading
      };
    case confirmationActionTypes.TOGGLE_LOADING_DOWNLOAD_PDF:
      return {
        ...state,
        isLoadingDownloadPdf: action.isLoadingDownloadPdf
      };

    default:
      return state;
  }
};

export default bookingConfirmationModule;

export const confirmationActionCreators = {
  getBookingStatusAction: () => async (dispatch: any, getState: any) => {
    const apiUrl = getState().global.apiUrl;
    // @ts-ignore
    const bookingResponseDataStorage = JSON.parse(localStorage.getItem("bookingResponseDataStorage"));

    // turn on global loader
    dispatch(globalActionCreators.toggleGlobalLoaderAction(true));

    try {
      const response = await ApiHelper.get(
        `${apiUrl}/bookings/${bookingResponseDataStorage?.booking_code}`,
        {},
        { "x-hash": bookingResponseDataStorage?.hash }
      );

      dispatch({
        type: confirmationActionTypes.SET_BOOKING_STATUS,
        bookingStatus: response.data
      });

      const isBookingStatusAllowed: boolean = isAllowedBookingStatus(response.data?.booking_status);

      // turn off isBookingStatusLoading when we get "BOOKED" || "ERROR" || "CANCELLED" status (or when we get API BE error)
      // or if we get from BE not allowed booking_status (isBookingStatusAllowed)
      // or in case Yellow bookings (when directly_bookable is set to false) and for PAYMENT_STATUS we get AUTHORIZED
      // or for PAYMENT_STATUS we get PAID
      if (
        (!response.data?.directly_bookable && response.data?.payment_status === "authorized") ||
        response.data?.payment_status === "paid" ||
        !isBookingStatusAllowed ||
        response.data?.booking_status === "booked" ||
        response.data?.booking_status === "error" ||
        response.data?.booking_status === "cancelled"
      ) {
        dispatch(confirmationActionCreators?.toggleBookingStatusLoaderAction(false));
      }
    } catch (err: any) {
      // if we get 500 or 504 from BE we should call EP again --> and we don't need to turn off loader and set apiError to true
      if (err?.response?.status === 500 || err?.response?.status === 504) {
        return;
      }
      // turn off isBookingStatusLoading only when we get API BE error (or when we get "BOOKED" || "ERROR" status)
      dispatch(confirmationActionCreators?.toggleBookingStatusLoaderAction(false));

      dispatch({
        type: confirmationActionTypes.SET_API_ERROR,
        apiError: true
      });
    } finally {
      // turn off global loader
      dispatch(globalActionCreators.toggleGlobalLoaderAction(false));
    }
  },
  toggleBookingStatusLoaderAction: (isBookingStatusLoading: boolean) => (dispatch: any) => {
    dispatch({
      type: confirmationActionTypes.TOGGLE_LOADING_BOOKING_STATUS,
      isBookingStatusLoading
    });
  },
  downloadConfirmationPdfAction:
    (pdfLink: string, hash: string, bookingCode: string) => async (dispatch: any, getState: any) => {
      dispatch({
        type: confirmationActionTypes.TOGGLE_LOADING_DOWNLOAD_PDF,
        isLoadingDownloadPdf: true
      });

      try {
        const response = await ApiHelper.get(pdfLink, {}, { "x-hash": hash }, "blob");

        // TODO: Download pdf could be a separate action (??)
        // Download PDF
        const url = URL.createObjectURL(response.data);
        const a = document.createElement("a");
        a.download = `${bookingCode}-confirmation.pdf`;
        a.href = url;
        a.target = "_self";
        a.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          a.remove();
          URL.revokeObjectURL(url);
        }, 100);
      } catch (err: any) {
      } finally {
        dispatch({
          type: confirmationActionTypes.TOGGLE_LOADING_DOWNLOAD_PDF,
          isLoadingDownloadPdf: false
        });
      }
    }
};
