import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
// stripe
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
// components
import AppButton from "../../ui/AppButton";

export default function StripeCheckoutForm({ confirmationUrl }) {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isPaymentReady, setIsPaymentReady] = useState(false);
  const [loadError, setLoadError] = useState(false);

  // states
  const hotelInfo = useSelector(state => state.hotel.hotelInfo);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: confirmationUrl
      }
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage(t("error.unexpected"));
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement
        id="payment-element"
        onReady={() => setIsPaymentReady(true)}
        onLoadError={() => setLoadError(true)}
        options={{
          terms: {
            applePay: "never",
            auBecsDebit: "never",
            bancontact: "never",
            card: "never",
            cashapp: "never",
            googlePay: "never",
            ideal: "never",
            paypal: "never",
            sepaDebit: "never",
            sofort: "never",
            usBankAccount: "never"
          }
        }}
      />
      {loadError && (
        <Box mt={2} mb={2}>
          {t("checkout.error-msg")}
        </Box>
      )}
      {isPaymentReady && (
        <Box mt={2} mb={2}>
          <AppButton
            label={t("checkout.pay")}
            disabled={isProcessing || !isPaymentReady}
            isLoading={isProcessing}
            onClick={() => null}
            disableRipple={true}
            customBackgroundColor={hotelInfo?.style?.button?.color}
            customActiveColor={hotelInfo?.style?.button?.active}
            customHoverColor={hotelInfo?.style?.button?.hover}
            customClickColor={hotelInfo?.style?.button?.click}
            fontSize="14px"
            type="submit"
            id="submit"
            dataAttribute="pay-button"
          />
        </Box>
      )}

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
