// TODO: Write test for this method

export const getFormattedLocaleString = (language: string | null): string => {
  switch (language) {
    case "de":
      return "de-DE";
    case "it":
      return "it-IT";
    case "fr":
      return "fr-FR";
    case "en":
      return "en-US";
    default:
      return "en-US";
  }
};
