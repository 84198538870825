import { getUnixTime } from "date-fns";
import ApiHelper from "../../common/utils/apiHelper";
// actions
import { globalActionCreators } from "../../common/store/modules/globalModule";
import { confirmationActionCreators } from "../../common/store/modules/bookingConfirmationModule";

export const bookingActionTypes = {
  SET_SUMMARY: "SET_SUMMARY",
  SET_INFO_SCHEMA_DETAILS: "SET_INFO_SCHEMA_DETAILS",
  SET_TOTAL_PRICE_DATA: "SET_TOTAL_PRICE_DATA",
  SET_BOOKING_RESPONSE_DATA: "SET_BOOKING_RESPONSE_DATA",
  TOGGLE_BOOKING_ERROR: "TOGGLE_BOOKING_ERROR",
  TOGGLE_PAYMENT_MODAL: "TOGGLE_PAYMENT_MODAL",
  TOGGLE_BOOKING_LOADER: "TOGGLE_BOOKING_LOADER"
};

interface IActions {
  SET_SUMMARY: string;
  SET_TOTAL_PRICE_DATA: string;
}

interface IBookingFormData {
  type: IActions["SET_SUMMARY"];
  bookingSummarySchema?: any;
  totalPriceData?: any;
  personalInfoSchema?: any;
}

type DispatchBookingFormData = (arg: IBookingFormData) => IBookingFormData;

export const initialState = {
  bookingSummarySchema: [],
  totalPriceData: {},
  bookingResponseData: null,
  bookingError: false,
  isBookingLoading: false,
  showPaymentModal: false
};

const bookingModule = (state = initialState, action: any) => {
  switch (action.type) {
    case bookingActionTypes.SET_SUMMARY:
      return {
        ...state,
        bookingSummarySchema: action.bookingSummarySchema
      };
    case bookingActionTypes.SET_TOTAL_PRICE_DATA:
      return {
        ...state,
        totalPriceData: action.totalPriceData
      };
    case bookingActionTypes.SET_INFO_SCHEMA_DETAILS:
      return {
        ...state,
        personalInfoSchema: action.personalInfoSchema
      };
    case bookingActionTypes.SET_BOOKING_RESPONSE_DATA:
      return {
        ...state,
        bookingResponseData: action.bookingResponseData
      };
    case bookingActionTypes.TOGGLE_BOOKING_ERROR:
      return {
        ...state,
        bookingError: action.bookingError
      };
    case bookingActionTypes.TOGGLE_PAYMENT_MODAL:
      return {
        ...state,
        showPaymentModal: action.showPaymentModal
      };
    case bookingActionTypes.TOGGLE_BOOKING_LOADER:
      return {
        ...state,
        isBookingLoading: action.isBookingLoading
      };

    default:
      return state;
  }
};
export default bookingModule;

export const bookingActionCreators = {
  getAvailabilityBookingFormDataAction:
    (formData: any, offerCode: string, timeslot: string) =>
    async (dispatch: DispatchBookingFormData, getState: any) => {
      const apiUrl = getState().global.apiUrl;

      // turn on the loader
      dispatch(globalActionCreators?.toggleGlobalLoaderAction(true));

      const availabilityApiParams = {
        ...formData,
        timeslot: timeslot,
        start_date: getUnixTime(new Date(formData?.start_date)),
        distribution_channel: "channelHotelGuestPlatform"
      };

      try {
        const response = await ApiHelper.post(`${apiUrl}/offers/${offerCode}/availability`, {}, availabilityApiParams);

        dispatch({
          type: bookingActionTypes.SET_TOTAL_PRICE_DATA,
          totalPriceData: response.data
        });
      } catch (err: any) {
      } finally {
        // turn off the loader
        dispatch(globalActionCreators?.toggleGlobalLoaderAction(false));
      }
    },
  loadInitialBookingFormDataAction:
    (formData: any, offerCode: string, timeslot: string) =>
    async (dispatch: DispatchBookingFormData, getState: any) => {
      const apiUrl = getState().global.apiUrl;

      // turn on the loader
      dispatch(globalActionCreators?.toggleGlobalLoaderAction(true));

      const availabilityApiParams = {
        ...formData,
        timeslot: timeslot,
        start_date: getUnixTime(new Date(formData?.start_date)),
        distribution_channel: "channelHotelGuestPlatform"
      };

      const promises = [];
      const availabilityResponse = ApiHelper.post(
        `${apiUrl}/offers/${offerCode}/availability`,
        {},
        availabilityApiParams
      );
      const summarySchemaResponse = ApiHelper.get(`${apiUrl}/schema/offers/${offerCode}/summary`);
      const infoSchemaResponse = ApiHelper.get(`${apiUrl}/schema/offers/${offerCode}/personal-information`);
      promises.push(availabilityResponse || {});
      promises.push(summarySchemaResponse || {});
      promises.push(infoSchemaResponse || {});

      try {
        const [availabilityData, summarySchemaData, infoSchemaData] = await Promise.all(promises);

        dispatch({
          type: bookingActionTypes.SET_SUMMARY,
          bookingSummarySchema: summarySchemaData.data
        });
        dispatch({
          type: bookingActionTypes.SET_TOTAL_PRICE_DATA,
          totalPriceData: availabilityData.data
        });
        dispatch({
          type: bookingActionTypes.SET_INFO_SCHEMA_DETAILS,
          personalInfoSchema: infoSchemaData.data
        });
      } catch (err: any) {
      } finally {
        // turn off the loader
        dispatch(globalActionCreators?.toggleGlobalLoaderAction(false));
      }
    },
  bookOfferAction:
    (infoFormData: any, summaryFormData: any, offerCode: string, timeslot: string, hotelCode: string, navigate: any) =>
    async (dispatch: any, getState: any) => {
      const apiUrl = getState().global.apiUrl;
      const language = localStorage.getItem("i18nextLng");

      // turn on loader
      dispatch({
        type: bookingActionTypes.TOGGLE_BOOKING_LOADER,
        isBookingLoading: true
      });

      // remove bookingResponseDataStorage from storage, before setting it again
      localStorage.removeItem("bookingResponseDataStorage");

      const bookingApiParams = {
        summary: {
          ...summaryFormData,
          start_date: getUnixTime(new Date(summaryFormData?.start_date)),
          timeslot: timeslot,
          offer_code: offerCode
        },
        personal_information: {
          ...infoFormData
        },
        hotel_stay_code: hotelCode
      };

      try {
        const response = await ApiHelper.post(`${apiUrl}/bookings`, {}, bookingApiParams);

        localStorage.setItem("bookingResponseDataStorage", JSON.stringify(response.data));

        // We can get API status 200, with booking_status=error --> in that case we should show error page instead of Payment Modal / Confirmation Page
        if (response.data?.booking_status === "error") {
          dispatch(bookingActionCreators?.toggleBookingErrorAction(true));
          return;
        } else {
          dispatch(bookingActionCreators?.toggleBookingErrorAction(false));

          dispatch({
            type: bookingActionTypes.SET_BOOKING_RESPONSE_DATA,
            bookingResponseData: response.data
          });

          // If price is 0 we will get payment_status=paid from API --> and we should redirect user directly to the /confirmation page (without payment modal)
          if (response.data?.payment_status === "paid") {
            // call booking status action, in order to set new value to the bookingStatus state
            await dispatch(confirmationActionCreators?.getBookingStatusAction());
            // redirect to confirmation

            await navigate(`/${language}/offers/${offerCode}/checkout/confirmation`, { replace: true });
          } else {
            // open payment modal (if status is not booked)
            dispatch(bookingActionCreators?.togglePaymentModalAction(true));
          }
        }
      } catch (err: any) {
        dispatch(bookingActionCreators?.toggleBookingErrorAction(true));
      } finally {
        // turn off loader
        dispatch({
          type: bookingActionTypes.TOGGLE_BOOKING_LOADER,
          isBookingLoading: false
        });
      }
    },
  toggleBookingErrorAction: (bookingError: boolean) => (dispatch: any) => {
    dispatch({
      type: bookingActionTypes.TOGGLE_BOOKING_ERROR,
      bookingError
    });
  },
  togglePaymentModalAction: (showPaymentModal: boolean) => (dispatch: any) => {
    dispatch({
      type: bookingActionTypes.TOGGLE_PAYMENT_MODAL,
      showPaymentModal
    });
  }
};
