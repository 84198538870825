import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
// helpers
import { getFormattedLocaleString } from "../helpers/getFormattedLocaleString";

interface AdyenCheckoutFormProps {
  confirmationUrl: string;
}

export default function AdyenCheckoutForm({ confirmationUrl }: AdyenCheckoutFormProps) {
  const [onCompleteResult, setOnCompleteResult] = useState(null);
  const [errorResult, setErrorResult] = useState(null);
  const language = localStorage.getItem("i18nextLng");

  const navigate = useNavigate();

  // states
  const bookingPaymentInfo = useSelector((state: any) => state.payment.bookingPaymentInfo);

  useEffect(() => {
    initializeAdyen();
  }, [bookingPaymentInfo]);

  const initializeAdyen = async () => {
    const checkout = await AdyenCheckout({
      environment: bookingPaymentInfo?.environment,
      clientKey: bookingPaymentInfo?.client_key,
      session: {
        id: bookingPaymentInfo?.session?.session_id,
        sessionData: bookingPaymentInfo?.session?.session_data
      },
      onPaymentCompleted: (result: any, component: any) => {
        setOnCompleteResult(result?.resultCode);
        if (result?.resultCode === "Authorised") {
          navigate(confirmationUrl, { replace: true });
        }
      },
      onError: (error: any, component: any) => {
        setErrorResult(error.message);
      },
      showPayButton: true,
      locale: getFormattedLocaleString(language)
    });

    checkout.create("card").mount("#card-container");
  };

  return (
    <div id="card-container">
      {onCompleteResult && <Box mt={1}>{onCompleteResult}</Box>}
      {errorResult && <Box mt={1}>{errorResult}</Box>}
    </div>
  );
}
