// TODO: Write test for this method

export const getUpdatedArray = (
  arr: Array<any>,
  index: number,
  fieldForUpdate: string,
  updatedValue: string | boolean
) => {
  return arr?.map((a, i) => {
    if (i === index) {
      return {
        ...a,
        [fieldForUpdate]: updatedValue
      };
    } else {
      return a;
    }
  });
};
