import { Route, createBrowserRouter, RouterProvider, createRoutesFromElements, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { defaultLanguage, supportedLanguages } from "../utils/translation/languagesConfig";
import { Fragment } from "react";
// Layout
import AppLayout from "../../components/AppLayout";
// --- PAGES ---
import Welcome from "../../pages/Welcome";
// Explore Pages
import Explore from "../../pages/Explore";
import OfferDetails from "../../pages/Explore/OfferDetails";
import NotFound from "../../pages/NotFound";
// Booking Pages
import Booking from "../../pages/Booking";
// SBB
import Sbb from "../../pages/PublicTransport/Sbb";
// Shared
import BookingConfirmation from "../../pages/Booking/BookingConfirmation";
// Test Routes:
import TestPage from "../../pages/TestPage";
// helpers
import { checkIsDefaultDomain } from "../utils/helpers/subdomainHelpers/checkIsDefaultDomain";

export const baseUrl = "";

// TBD in the future: handling base url based on first LOCALE path
// const firstPath = window.location.pathname.split("/")[1];
// const firstPath = window.location.pathname;
// export const baseUrl = supportedLanguages.includes(firstPath) ? firstPath : defaultLanguage;

export const mainRoutes: Array<any> = [
  {
    path: baseUrl,
    element: <Explore />
  },
  {
    path: "/offers/:code/",
    element: <OfferDetails />
  },
  {
    path: "/offers/:code/checkout/",
    element: <Booking />
  },
  {
    path: "/offers/:code/checkout/confirmation",
    element: <BookingConfirmation />
  },
  {
    path: "/transport/sbb/",
    element: <Sbb />
  },
  {
    path: "/transport/sbb/confirmation",
    element: <BookingConfirmation />
  }
];

function AppRouter() {
  // states
  const defaultDomain = useSelector((state: any) => state.global.defaultDomain);

  const language = localStorage.getItem("i18nextLng");
  const isDefaultDomain = checkIsDefaultDomain(window.location.hostname, defaultDomain);

  return (
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <Route path={"/"} element={<AppLayout />}>
            {isDefaultDomain ? (
              <>
                <Route path={baseUrl + "/:language"} element={<Welcome />} />
                <Route path="/" element={<Navigate to={`/${language}`} replace />} />
              </>
            ) : (
              <>
                {mainRoutes?.map(route => {
                  return (
                    <Fragment key={route.path}>
                      <Route key={route.path} path={route.path} element={route.element} />
                      <Route key={route.path} path={"/:language" + route.path} element={route.element} />
                      <Route path={route.path} element={<Navigate to={"/" + language + route.path} replace />} />
                    </Fragment>
                  );
                })}
              </>
            )}

            {/* 👇️ only match this when no other routes match */}
            <Route path={`/:language/*`} element={<NotFound />} />
            {/* 👇️ test page */}
            <Route path={`${baseUrl}/get-local-test-page`} element={<TestPage />} />
            {/* 👇️ only match this when no other routes match */}
          </Route>
        )
      )}
    />
  );
}

export default AppRouter;
