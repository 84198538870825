import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import Checkbox from "@mui/material/Checkbox";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
// @ts-ignore
import { useTheme } from "@mui/material/styles";
// hooks
import { useActions } from "../../../../common/hooks/useActions";
// actions
import { sbbActionCreators } from "../sbbModule";
// components
import AppFormInput from "../../../../components/form/AppFormInputs/AppFormInput";
import AppButton from "../../../../components/ui/AppButton";

interface SbbSummaryTypes {
  onBuyTicket: any;
}

export default function SbbSummary({ onBuyTicket }: SbbSummaryTypes) {
  const { t } = useTranslation();
  const theme = useTheme();

  // states
  const hotelInfo = useSelector((state: any) => state.hotel.hotelInfo);
  const prebookPriceInfo = useSelector((state: any) => state.sbb.prebookPriceInfo);
  const dataForBooking = useSelector((state: any) => state.sbb.dataForBooking);
  const errorField = useSelector((state: any) => state.errorField.errorField);
  const sbbActiveOffer = useSelector((state: any) => state.sbb.sbbActiveOffer);
  // actions
  const updateFormForBooking = useActions(sbbActionCreators?.updateFormForBookingAction, []);
  const resetPrebookDataInfo = useActions(sbbActionCreators?.resetPrebookDataInfoAction, []);

  /*  const handleCopyConfirmationCheckbox = (e: any) => {
    updateFormForBooking({
      ...dataForBooking,
      copyConfirmationForm: e.target.checked
    });
  };*/

  const handleGuestEmail = (e: any) => {
    updateFormForBooking({
      ...dataForBooking,
      emailForm: e.target.value
    });
  };

  const handleComment = (e: any) => {
    updateFormForBooking({
      ...dataForBooking,
      commentForm: e.target.value
    });
  };

  const handeEditTicketOptionButton = () => {
    if (window.confirm(t("transport.editOptionText"))) {
      resetPrebookDataInfo();
    }
  };

  const getSummaryRouteTitle = (): string => {
    if (sbbActiveOffer?.code) {
      return sbbActiveOffer?.name;
    } else {
      return `${prebookPriceInfo?.origin?.name} - ${prebookPriceInfo?.destination?.name}`;
    }
  };

  return (
    prebookPriceInfo && (
      <Box sx={{ display: "flex", flexDirection: "column" }} pb={3} pt={3}>
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between" }}>
            <Box>
              <Box className="summary-option-title" mb={2}>
                {t("transport.ticketOption")}
              </Box>
              <Box className="summary-option-title default-text-transform">{getSummaryRouteTitle()}</Box>
            </Box>
            <Grid item sx={{ width: "auto", height: "40px" }} mt={{ xs: 1.5, sm: 0 }}>
              <AppButton
                label={t("transport.editBtn")}
                onClick={handeEditTicketOptionButton}
                disableRipple={true}
                customBackgroundColor={theme.palette.primary.dark}
                customHoverColor={theme.palette.primary.dark}
                fontSize="14px"
              />
            </Grid>
          </Box>
          <Grid container className="summary-passenger-box" mt={2} mb={2} pb={2} pt={2}>
            {prebookPriceInfo?.passengers?.length > 0 &&
              prebookPriceInfo?.passengers?.map((passenger: any) => {
                return (
                  <Box
                    key={Math.random()}
                    sx={{ display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center" }}
                  >
                    <Grid item xs={3} pl={{ xs: 1, sm: 0 }}>
                      {passenger?.first_name} {passenger?.last_name}
                    </Grid>
                    <Grid item xs={3}>
                      {format(new Date(passenger?.birthdate), "dd.MM.yyyy")}
                    </Grid>
                    <Grid item xs={3}>
                      {passenger?.member_card ? "full-fare" : "half-fare"}
                    </Grid>
                    <Grid item xs={3}>
                      {passenger?.price} {passenger?.currency}
                    </Grid>
                  </Box>
                );
              })}
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/*
                  // TODO: Remove this after confirmation in comments: --> Feature 4259: Transport checkout
                  <Checkbox
                checked={dataForBooking?.copyConfirmationForm}
                onChange={handleCopyConfirmationCheckbox}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Box className="summary-copy-confirmation-txt">{t("transport.checkboxConfirmation")}</Box>*/}
            </Box>
            <Box className="summary-total-price-label" mr={{ xs: 0, sm: 3, md: 6 }}>
              {t("transport.totalPrice")}: {prebookPriceInfo?.price} {prebookPriceInfo?.currency}
            </Box>
          </Box>
        </Box>
        <Grid container width={{ sm: "100%", md: "50%" }} mt={2}>
          <Grid item xs={12} mb={2} sx={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
            <Grid item xs={3}>
              <Box sx={{ fontSize: { xs: 12, sm: 14 } }}>{t("transport.guestEmailLabel")} *</Box>
            </Grid>
            <Grid item xs={9} ml={1}>
              <AppFormInput
                name={"emailForm"}
                type="string"
                value={dataForBooking?.emailForm}
                onChange={handleGuestEmail}
                error={errorField?.emailForm}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
            <Grid item xs={3}>
              <Box sx={{ fontSize: { xs: 12, sm: 14 } }}>{t("transport.commentLabel")}</Box>
            </Grid>
            <Grid item xs={9} ml={1}>
              <AppFormInput
                name={"commentForm"}
                type="string"
                value={dataForBooking?.commentForm}
                onChange={handleComment}
                error={errorField?.commentForm}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ width: { xs: "100%", sm: "100%", md: "30%" }, margin: "60px auto 0 auto" }}>
          <AppButton
            label={t("transport.buyTicketsBtn")}
            onClick={onBuyTicket}
            disableRipple={true}
            customBackgroundColor={hotelInfo?.style?.button?.color}
            customActiveColor={hotelInfo?.style?.button?.active}
            customHoverColor={hotelInfo?.style?.button?.hover}
            customClickColor={hotelInfo?.style?.button?.click}
            fontSize="14px"
          />
        </Grid>
      </Box>
    )
  );
}
