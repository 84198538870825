import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { decode } from "html-entities";
import Box from "@mui/material/Box";
// components
import CollapsibleBox from "../../components/ui/CollapsibleBox";
import AppAutocomplete from "../../components/form/AppAutocomplete";
import ThreeDotsLoader from "../../components/ui/ThreeDotsLoader";
// @ts-ignore
import { useTheme } from "@mui/material/styles";
// hooks
import { useActions } from "../../common/hooks/useActions";
import { useDebounce } from "../../common/hooks/useDebounce";
// helpers
import { getSearchOffersTitle } from "./helpers/getSearchOffersTitle";
import { getOptionsForSearch } from "./helpers/getOptionsForSearch";
import { mockOfferCodeKeyword } from "./helpers/constant";
import { getCurrentDevice } from "../../common/utils/helpers/currentDeviceHelpers/getCurrentDevice";
import { isFilterOffersActive } from "./helpers/isFilterOffersActive";
// actions
import { exploreActionCreators } from "./exploreModule";

interface ExploreSidebarProps {
  keywordParamDefault: string | null;
  resetFilters: () => void;
  device: string;
}

const boxStyle = { mb: 3 };
const queryLimit = 3;

export default function ExploreSidebar({ keywordParamDefault, resetFilters, device }: ExploreSidebarProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const language = localStorage.getItem("i18nextLng");

  // states
  const hotelInfo = useSelector((state: any) => state.hotel.hotelInfo);
  const offersSearchResults = useSelector((state: any) => state.explore.offersSearchResults);
  const isLoadingSearchResults = useSelector((state: any) => state.explore.isLoadingSearchResults);
  const selectedOfferFromSearch = useSelector((state: any) => state.explore.selectedOfferFromSearch);
  const queryOffers = useSelector((state: any) => state.explore.queryOffers);
  const totalResultsSearchedOffers: number = useSelector((state: any) => state.explore.totalResultsSearchedOffers);
  const showSearchResultsLoader = useSelector((state: any) => state.explore.showSearchResultsLoader);

  // actions
  const searchOffers = useActions(exploreActionCreators?.searchOffersAutocompleteAction, []);
  const resetSearchOffersResults = useActions(exploreActionCreators?.resetSearchOffersResultsAction, []);
  const getOffers = useActions(exploreActionCreators?.getOffersAction, []);
  const setSelectedOfferFromSearch = useActions(exploreActionCreators?.setSelectedOfferFromSearchAction, []);
  const setQueryOffers = useActions(exploreActionCreators?.setQueryOffersAction, []);
  const setSearchedQuery = useActions(exploreActionCreators?.setSearchedQueryAction, []);
  const setShowSearchResultsLoader = useActions(exploreActionCreators?.setShowSearchResultsLoaderAction, []);

  useEffect(() => {
    // if keyword comes from direct link, we should save it in redux
    if (keywordParamDefault) {
      setSelectedOfferFromSearch({ name: keywordParamDefault, offer_code: mockOfferCodeKeyword });
      setSearchedQuery(keywordParamDefault);
    }
  }, [language]);

  const callSearchOffers = () => {
    if (queryOffers?.length > 2) {
      searchOffers(queryOffers);
    }
  };

  const debounceCallSearchOffers = useDebounce(callSearchOffers, 400);

  const handleSearchOffersInputChange = (event: any) => {
    resetSearchOffersResults();

    if (event?.type === "change") {
      // Turn on three dots loader
      setShowSearchResultsLoader(true);
      debounceCallSearchOffers();
      setQueryOffers(event.target.value);
    }
  };

  const onChangeSearchOfferAutoComplete = (event: any, value: any, reason: string) => {
    // disable click on option if there are no data from API (offersSearchResults)
    if (reason === "selectOption") {
      setSelectedOfferFromSearch(value);
      if (value?.offer_code === mockOfferCodeKeyword) {
        // go here when user clicks on first ("search for...") item from autocomplete list --> update the list
        setSearchParams({ keyword: value?.name });
        getOffers({ keyword: value?.name, offset: 1 }, device);
        setSearchedQuery(value?.name);
      } else {
        navigate(`offers/${value?.offer_code}`, {
          state: {
            offer_code: value?.offer_code,
            name: value?.name
          }
        });
      }
    }
  };

  const renderSearchCollapsibleTitle = (): string => {
    return getSearchOffersTitle(
      t("explore.search.titleForCollapsibleSearchBox"),
      isFilterOffersActive(selectedOfferFromSearch?.offer_code)
    );
  };

  return (
    <Box className="explore-sidebar">
      <Box sx={{ ...boxStyle }}>
        <CollapsibleBox
          title={t("explore.titleForCollapsibleStayBox")}
          isExpanded={true}
          backgroundColor={hotelInfo?.style?.page?.box_color}
          dataAttribute="your-stay"
        >
          <Trans>
            <Box sx={{ color: theme.palette.primary.dark, fontSize: { xs: "14px", md: "15px" } }}>
              {decode(
                t("explore.textForCollapsibleStayBox", {
                  hotel: decode(hotelInfo?.name),
                  city: decode(hotelInfo?.address?.city)
                })
              )}
            </Box>
          </Trans>
        </CollapsibleBox>
      </Box>
      <Box sx={{ ...boxStyle }}>
        <CollapsibleBox
          title={renderSearchCollapsibleTitle()}
          isExpanded={getCurrentDevice() !== "phone"}
          backgroundColor={hotelInfo?.style?.page?.box_color}
          dataAttribute="search-box"
        >
          <AppAutocomplete
            // TBD: Query offers added for handling Clear Icon, check logic of input value source and of setting input value
            queryOffers={queryOffers}
            queryLimit={queryLimit ? queryLimit : 0}
            showSearchResultsLoader={showSearchResultsLoader}
            loading={isLoadingSearchResults && queryOffers?.length >= queryLimit}
            id="searchOffers"
            name="searchOffers"
            freeSolo={true}
            size="small"
            onInputChange={handleSearchOffersInputChange}
            onChangeAutoComplete={onChangeSearchOfferAutoComplete}
            handleClearButton={resetFilters}
            noOptionsText={
              queryOffers?.length >= queryLimit ? t("explore.search.noResults") : t("explore.search.typeToSearch")
            }
            label={
              queryOffers?.length > 0 || (selectedOfferFromSearch && !selectedOfferFromSearch?.reset)
                ? ""
                : t("explore.search.typeToSearch")
            }
            options={getOptionsForSearch(queryOffers, offersSearchResults, mockOfferCodeKeyword)}
            filterOptions={(options: any) => options}
            getOptionLabel={(option: any) => `${option?.name}`}
            value={selectedOfferFromSearch}
            renderOption={(props: any, option: any) => {
              const loading = isLoadingSearchResults && queryOffers?.length >= queryLimit;

              const quote: false | string = option.offer_code === mockOfferCodeKeyword && `"`;
              const isLastOption: boolean = props["data-option-index"] === offersSearchResults?.length;
              return (
                <Box key={option.offer_code}>
                  {loading || (showSearchResultsLoader && queryOffers?.length >= queryLimit) ? (
                    <>
                      <Box {...props}>
                        <div className="explore-sidebar__autocomplete-list" data-cy="autocomplete-list">
                          {option.offer_code === mockOfferCodeKeyword && (
                            <>
                              <span
                                className="explore-sidebar__autocomplete-list__search-for"
                                data-cy="autocomplete-list-label"
                              >
                                {t("explore.search.searchFor")}
                              </span>
                              <span
                                className="explore-sidebar__autocomplete-list__item"
                                data-cy={`autocomplete-list-item-${option.name}`}
                              >
                                {quote}
                                {option.name}
                                {quote}
                              </span>
                            </>
                          )}
                        </div>
                      </Box>
                      {offersSearchResults?.length === 0 && (
                        <span className="explore-sidebar__autocomplete-list__footer">
                          <ThreeDotsLoader />
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      <Box {...props}>
                        <div className="explore-sidebar__autocomplete-list" data-cy="autocomplete-list">
                          {option.offer_code === mockOfferCodeKeyword ? (
                            <>
                              <span
                                className="explore-sidebar__autocomplete-list__search-for"
                                data-cy="autocomplete-list-label"
                              >
                                {t("explore.search.searchFor")}
                              </span>
                              <span
                                className="explore-sidebar__autocomplete-list__item"
                                data-cy={`autocomplete-list-item-${option.name}`}
                              >
                                {quote}
                                {option.name}
                                {quote}
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                className="explore-sidebar__autocomplete-list__item"
                                data-cy={`autocomplete-list-item-${option.name}`}
                              >
                                {quote}
                                {option.name}
                                {quote}
                              </span>
                              <span
                                className="explore-sidebar__autocomplete-list__subtitle"
                                data-cy="list-item-subtitle"
                              >
                                {option?.provider_name || "missing field"}{" "}
                              </span>
                            </>
                          )}
                        </div>
                      </Box>
                      {isLastOption && !loading && (
                        <span
                          className="explore-sidebar__autocomplete-list__footer label"
                          data-cy="autocomplete-list-footer"
                        >
                          {totalResultsSearchedOffers} {totalResultsSearchedOffers > 1 ? "results" : "result"}
                        </span>
                      )}
                    </>
                  )}
                </Box>
              );
            }}
          />
        </CollapsibleBox>
      </Box>
    </Box>
  );
}
