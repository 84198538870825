export const supportedLanguages = [
  { abr: "en", language: "English" },
  { abr: "fr", language: "French" },
  { abr: "de", language: "Deutsch" },
  { abr: "it", language: "Italian" }
];

export const supportedLngs = ["en", "fr", "de", "it"];
export const defaultLanguage = "en";

// TODO: Consider add below instead two separate as above
// export const locale = {
//     default: 'en',
//     languages: [en, de...]
// }
